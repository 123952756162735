import {setBodyClasses} from "./dev/js/helper";
import Posts from "./dev/js/posts";
import Video from "./dev/js/video";

'use strict';

( function ( $, w ) {
  
  var $window = $( w );
  
  // if needed for css
  setBodyClasses();
  
  // to have menu item "kontakt" open mail
  $('.elementor-nav-menu .mail').emailReplace();
  
  // make anchors work (see README for more instructions)
  $window.on( 'elementor/frontend/init', function() {
      new Posts();
      new Video();
  });
  
} ( jQuery, window ) );