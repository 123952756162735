/**
 * Handles the volume/mute button
 * for the background video
 * Needs to classes to be set (see Readme)
 */
export default class Video {

    constructor( buttonSelector = '#btn-video-audio', videoBgSelector = '#video-background' ) {

        this.button = document.querySelector(buttonSelector);
        this.video = document.querySelector(`${videoBgSelector} video`);
        if(this.button && this.video) {
            this.init();
        }
    }
    
    init() {
        this.video.addEventListener('canplaythrough', this.handleVideoLoaded.bind(this));
    }

    handleVideoLoaded() {
        this.button.classList.add('show');
        this.button.addEventListener('click', this.handleClickButton.bind(this));
    }

    handleClickButton(e) {
        if( this.video.muted !== false ) {
            this.video.muted = false;
            this.button.classList.add('playing');
        }
        else {
            this.video.muted = true;
            this.button.classList.remove('playing');
        }
    }
}