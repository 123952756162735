export const setBodyClasses = () => {
    
  const isSafari        = /^((?!chrome|android).)*safari/i.test( navigator.userAgent ),
        isFirefox       = /^((?!chrome|android).)*firefox/i.test( navigator.userAgent ),
        isChrome        = /chrome/i.test( navigator.userAgent ),
        isEdge          = /Edge/i.test( navigator.userAgent ) || /Edg/i.test( navigator.userAgent ),
        isMac           = /Macintosh/i.test( navigator.userAgent ),
        isWindows       = /Windows/i.test( navigator.userAgent ),
        isMobileDevice  = /Mobi/.test( navigator.userAgent );
  
  if( isSafari ) {
    document.body.classList.add( 'safari' );
  }
  if( isFirefox ) {
    document.body.classList.add( 'firefox' );
  }
  if( isChrome ) {
    document.body.classList.add( 'chrome' );
  }
  if( isEdge ) {
    document.body.classList.add( 'edge' );
  }
  if( isMac ) {
    document.body.classList.add( 'macintosh' );
  }
  if( isWindows ) {
    document.body.classList.add( 'windows' );
  }
  if( isMobileDevice ) {
    document.body.classList.add( 'mobile' );
  }
}