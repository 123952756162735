/**
 * Adds id's to posts article to have anchor to jump to
 * Category needs to be added manual in elementer attributes
 * like this:
 * data-boe-category="CATEGORYNAME"
 * Can then be targeted with post-[CATEGORYNAME]-[1|2|..]
 */
export default class Posts {

    constructor( selector = '.elementor-widget-posts' ) {

        const container = document.querySelectorAll(selector);
        container.forEach(container => {
            this.container = container;
            this.posts = this.container?.querySelectorAll(selector + ' article');
            // get type to switch inits
            this.type = container.getAttribute('data-widget_type');
            switch( this.type ) {
                case "posts.number":
                case "posts.thumbnail":
                    // nothing yet
                    break;
                default:
                    this.initFull();
                    break;
            }
        });
    }

    // == Start full content methods =================================================================================================== //
    initFull() {
        const category = this.getCategory();
        if( this.getCategory() ) {
            this.addAnchor(category);
        } else {
            console.log(`Es muss noch eine Kategorie in Elementor Attributes angelegt werden - siehe data-boe-category|regale-stehend`);
        }
        if( this.isLoggedIn() ) {
            this.addEditToPost();
        }
        this.checkLinkAnchor();
    }

    getCategory() {
        return this.container.getAttribute('data-boe-category');
    }

    addAnchor( category ) {
        this.posts.forEach(( post, i ) => {
            post.insertAdjacentHTML('afterbegin', `<div class="elementor-widget-menu-anchor"><div id="post-${ category }-${ i + 1 }" class="elementor-menu-anchor"></div></div>`);
        });
    }

    isLoggedIn() {
        return document.body.classList.contains('logged-in');
    }

    addEditToPost() {

        this.posts.forEach(( post, i ) => {

            const postInfo = post.querySelector('[data-elementor-type="wp-post"]');

            if( postInfo && boe_variables?.baseurl ) {

                const title = post.querySelector('.boe-single-title .elementor-widget-container');
                const id = postInfo.getAttribute('data-elementor-id');
                const url = `${ boe_variables.baseurl }/wp-admin/post.php?post=${ id }&action=elementor`;

                title.insertAdjacentHTML('beforeend', `<a class="boe-edit-post" href="${ url }" target="_blank">Produkt bearbeiten</a>`);
            }
        });
    }

    checkLinkAnchor() {

        var target = window.location.hash;

        // only if #
        if( target ) {
            let count = 0; // to cancel interval if not working
            const check_complete_interval = setInterval(function() {

                count++;
                const anchor = target.replace('#', '');
                const anchorDom = document.getElementById(anchor);
                //if (anchorDom || document.readyState === 'complete' || count > 30) {
                // check if anchor is in DOM
                if( anchorDom || count > 30 ) {

                    // bug Chrome & Safari https://stackoverflow.com/questions/6970769/window-location-hash-location-hash-does-not-work-in-webkit-safari-chrome
                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    const isChrome = /chrome/i.test(navigator.userAgent);
              
                    if( isSafari || isChrome ) {
                        location.hash = '';
                        window.location.hash = '';
                        window.location.href = target;
                    } else {
                        location.hash = target;
                    }

                    clearInterval(check_complete_interval);
                }
            }, 100);
        }
    }

    // == End full content methods ==================================================================================================== //
}